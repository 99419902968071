import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AllApis from "app/Apis";
import FullScreenLoader from "app/components/ListingPageLoader";
import {
  getAllReadySheetFormHistory,
  getAllReadySheetFormList,
} from "app/redux/actions/inventoryAction";
import dayjs from "dayjs";
import { Axios } from "index";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ReadySheetFormHistory from "./readySheetFormHistory";
import ReadySheetFormList from "./readySheetFormList";

const disabledStyling = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#475259",
  },
  "& .MuiInputLabel-root": {
    color: "#475259", // Change label color to blue
  },
};

const ReadySheetFormformList = () => {
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [groupNoList, setGroupNoList] = useState([]);
  const [emptyVal, setEmptyVal] = useState();
  const [palleteList, setPalleteList] = useState([]);
  const [addGroup, setAddGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    from: "",
    to: "",
  });
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const { inventoryReducer } = useSelector((state) => state);

  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("updated_at");
    setSearchTerm("");
  };

  const handleSearch = (search) => {
    setPage(1);
    if (value == 0) {
      dispatch(getAllReadySheetFormList(search, sort, sortBy, page, filters));
    } else if (value == 1) {
      dispatch(
        getAllReadySheetFormHistory(search, sort, sortBy, page, filters)
      );
    }
    setLoaded(true);
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     debouncedHandleSearch(searchTerm);
  //   }
  //   return () => {
  //     debouncedHandleSearch.cancel();
  //   };
  // }, [searchTerm]);

  useEffect(() => {
    //to deal with search lagging
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    } else {
      setLoaded(true);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleFilter = () => {
    setPage(1);
    if (value == 0) {
      dispatch(getAllReadySheetFormList(searchTerm, sort, sortBy, "", filters));
    } else if (value == 1) {
      dispatch(
        getAllReadySheetFormHistory(searchTerm, sort, sortBy, "", filters)
      );
    }
  };
  const handleClear = () => {
    TabChange();
    if (value == 0) {
      dispatch(getAllReadySheetFormList(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(getAllReadySheetFormHistory(searchTerm, sort, sortBy, page, {}));
    }
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };

  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    TabChange();
    setAddGroup([]);
    if (newValue == 0) {
      dispatch(
        getAllReadySheetFormList(searchTerm, sort, sortBy, page, filters)
      );
    } else if (newValue == 1) {
      dispatch(
        getAllReadySheetFormHistory(searchTerm, sort, sortBy, page, filters)
      );
    }
  };

  const exportReadySheetFormCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.readySheetForm,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    try {
      const groupNoList = await Axios.get(`${AllApis.dropdownList.group_no}`);
      setGroupNoList(groupNoList?.data?.result);
      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (value == 0) {
      dispatch(
        getAllReadySheetFormList(searchTerm, sort, sortBy, page, filters)
      );
    } else if (value == 1) {
      dispatch(
        getAllReadySheetFormHistory(searchTerm, sort, sortBy, page, filters)
      );
    }
  }, [sort, page]);

  useEffect(() => {
    //to deal with search input lagging
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    // Clean-up function to clear the timer in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Typography variant="h1" sx={{ mt: -4 }}>
        Ready Sheet Form
      </Typography>
      <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Filters
        </Typography>
        <Box
          sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
        >
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={groupNoList || []}
              getOptionLabel={(option) => option?.toString() || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "group_data.group_no": newValue != null ? newValue : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Group No" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={palleteList || []}
              getOptionLabel={(option) => option?.pallete_no || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  ready_sheet_form_pallete_no:
                    newValue != null ? newValue?.pallete_no : "",
                  ready_sheet_form_physical_location:
                    newValue != null ? newValue?.item_physical_location : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.pallete_no}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Pallet No" />
              )}
            />
          </Div>
          <FormControl sx={{ width: "23%" }} size="small">
            {/* <InputLabel id="demo-select-small-label">
              Physical Location
            </InputLabel>
            <Select
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%" }}
              size="small"
              labelId="demo-select-small-label"
              id="demo-simple-select"
              label="Physical Location"
              name="physical_location"
              defaultValue={""}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  ready_sheet_form_physical_location: e.target.value
                    ? e.target.value
                    : "",
                });
              }}
            >
              <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
              <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
              <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
              <MenuItem value={"VENEER SECTION"}>VENEER SECTION</MenuItem>
              <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
            </Select> */}
            {(filters?.ready_sheet_form_physical_location == "" ||
              filters?.ready_sheet_form_physical_location == undefined) && (
              <InputLabel id="demo-select-small-label">
                Physical Location
              </InputLabel>
            )}
            <TextField
              multiline
              size="small"
              label={
                (filters?.ready_sheet_form_physical_location != "" ||
                  filters?.ready_sheet_form_physical_location != undefined) &&
                "Physical Location"
              }
              value={filters?.ready_sheet_form_physical_location || ""}
              sx={{ width: "100%", ...disabledStyling }}
              name="ready_sheet_form_physical_location"
              disabled
              inputProps={{ shrink: true }}
              InputLabelProps={{
                style: { color: "#98A3AA" }, // Change the color of the label here
              }}
            />
          </FormControl>

          <FormControl sx={{ width: "23%" }} size="small">
            <InputLabel id="demo-select-small-label">Status</InputLabel>
            <Select
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%" }}
              size="small"
              labelId="demo-select-small-label"
              id="demo-simple-select"
              label="Status"
              name="status"
              defaultValue={""}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  status: e.target.value ? e.target.value : "",
                });
              }}
            >
              <MenuItem value={"available"}> Available</MenuItem>
              <MenuItem value={"not available"}>Not Available</MenuItem>
            </Select>
          </FormControl>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="from"
                format="DD-MM-YYYY"
                name="from"
                label={
                  value == 0
                    ? "From Date of Ready Sheet Form"
                    : "From Date of Ready Sheet Form"
                }
                sx={datepickerStyling}
                defaultValue={filters.from !== "" ? dayjs(filters.from) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    from: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {filters?.to && !filters?.from && (
              <Typography
                sx={{
                  color: "#E73145",
                  fontSize: "0.75rem",
                  ml: 1,
                  mt: 0.5,
                }}
              >
                {value == 0
                  ? "Select From Date of Ready Sheet Form"
                  : "Select From Date of Ready Sheet Form"}
              </Typography>
            )}
          </Div>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="to"
                format="DD-MM-YYYY"
                name="to"
                label={
                  value == 0
                    ? "To Date of Ready Sheet Form"
                    : "To Date of Ready Sheet Form"
                }
                sx={datepickerStyling}
                defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    to: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {!filters?.to && filters?.from && (
              <Typography
                sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
              >
                {value == 0
                  ? "Select To Date of Ready Sheet Form"
                  : "Select To Date of Ready Sheet Form"}
              </Typography>
            )}
          </Div>
        </Box>
        <Box sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}>
          <Div>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            {/* <Button variant="outlined" onClick={handleClears}> */}
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Div>
          {permissions?.ready_sheet_form_view && value == 0 && (
            <Button
              disabled={inventoryReducer?.readySheetForm?.length}
              variant="contained"
              onClick={async () => {
                exportReadySheetFormCsv(filters);
              }}
            >
              Export CSV
            </Button>
          )}
        </Box>
      </Div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Ready Sheet Form List" {...a11yProps(0)} />
            <Tab label="Ready Sheet Form History" {...a11yProps(1)} />
          </Tabs>
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setLoaded(false);
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("updated_at");
                if (value == 0) {
                  dispatch(
                    getAllReadySheetFormList(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filters
                    )
                  );
                } else if (value == 1) {
                  dispatch(
                    getAllReadySheetFormHistory(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filters
                    )
                  );
                }
              }
            }}
            sx={{ width: 300, ml: "auto" }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Box>
        <CustomTabPanel value={value} index={0}>
          {loaded ? (
            <ReadySheetFormList
              filter={filters}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              addGroup={addGroup}
              setAddGroup={setAddGroup}
              palleteList={palleteList}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {loaded ? (
            <ReadySheetFormHistory
              filter={filters}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              addGroup={addGroup}
              setAddGroup={setAddGroup}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default ReadySheetFormformList;
