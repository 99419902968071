/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { formatDate } from "app/components/Function/formatDate";
import { getIssueForPressingList } from "app/redux/actions/factoryAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatePressingModal from "../Modal/createPressingModal";
import { CuttingDetailsModal } from "../Modal/cuttingDetailsModal";
import FullScreenLoader from "app/components/ListingPageLoader";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import AllApis from "app/Apis";
import { Axios } from "index";
import Swal from "sweetalert2";

export default function IssuedForPressingTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
  addGroup,
  setAddGroup,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { issueForPressing, error, TotalPage, loading } = useSelector(
    (state) => state.factoryReducer
  );
  const [open, setOpen] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getIssueForPressingList(searchTerm, sort, sortBy, newPage, filter)
    );
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "addToPressing":
        setCreateModal(true);
        setRowData(menuItem.data);
        break;
      case "revert":
        revertIssuePressing(menuItem?.data?._id);
        break;
    }
  };

  const revertIssuePressing = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Revert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.post(
            AllApis.pressing.revertIssuePressing(id),
            {},
            config
          );
          if (response) {
            dispatch(getIssueForPressingList("", "desc", "updated_at", 1, {}));
            Swal.fire({
              title: "Reverted",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error?.response?.data?.error,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_name"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_name"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_code"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_code"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "ready_sheet_form_history_details.ready_sheet_form_length"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "ready_sheet_form_history_details.ready_sheet_form_length"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Length
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "ready_sheet_form_history_details.ready_sheet_form_width"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "ready_sheet_form_history_details.ready_sheet_form_width"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Width
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "ready_sheet_form_history_details.ready_sheet_form_approved_pcs"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "ready_sheet_form_history_details.ready_sheet_form_approved_pcs"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "ready_sheet_form_history_details.ready_sheet_form_sqm"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "ready_sheet_form_history_details.ready_sheet_form_sqm"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Sqm
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "ready_sheet_form_history_details.ready_sheet_form_pallete_no"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "ready_sheet_form_history_details.ready_sheet_form_pallete_no"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Pallet No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "165px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "ready_sheet_form_history_details.ready_sheet_form_physical_location"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "ready_sheet_form_history_details.ready_sheet_form_physical_location"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Ready Sheet Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Issued Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              {permissions?.tapping_create && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {issueForPressing?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.ready_sheet_form_history_details?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_details?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_name
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_details?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_code
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.ready_sheet_form_history_details
                      ?.ready_sheet_form_length
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.ready_sheet_form_history_details
                      ?.ready_sheet_form_width
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.ready_sheet_form_history_details
                      ?.ready_sheet_form_approved_pcs
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.ready_sheet_form_history_details?.ready_sheet_form_sqm}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.ready_sheet_form_history_details
                      ?.ready_sheet_form_pallete_no
                  }
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {
                    row?.ready_sheet_form_history_details
                      ?.ready_sheet_form_physical_location
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.cutting_details?.cutting_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {formatDate(row?.created_at)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <AddCircleIcon />,
                        title: "Add To Pressing",
                        action: "addToPressing",
                        data: row,
                        show: permissions?.pressing_create,
                      },
                      {
                        icon: <SettingsBackupRestoreIcon />,
                        title: "Revert",
                        action: "revert",
                        data: row,
                        show: permissions?.pressing_edit,
                      },
                    ].filter((ele) => ele?.show)}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {createModal && (
            <CreatePressingModal
              open={createModal}
              onClose={() => setCreateModal(false)}
              rowData={rowData}
            />
          )}
          {open && (
            <CuttingDetailsModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
