import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import FullScreenLoader from "app/components/ListingPageLoader";
import GroupHistoryList from "app/pages/Grouping/ListGrouping/groupHistoryList";
import {
  getAllCreatedGroups,
  getAllGroupHistory,
} from "app/redux/actions/groupingAction";
import dayjs from "dayjs";
import { Axios } from "index";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import CreatedGroups from "./createdGroups";

const GroupedVeneerList = () => {
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [groupNoList, setGroupNoList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [emptyVal, setEmptyVal] = useState();
  const [addGroup, setAddGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    from: "",
    to: "",
  });
  const { groupingReducer, factoryReducer, inventoryReducer } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const { createdGroups, error, TotalPage, loading } = useSelector(
    (state) => state.groupingReducer
  );

  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("updated_at");
    setSearchTerm("");
  };

  const filterFun = (type) => {
    const filter = {};
    for (let i in filters) {
      if (filters[i] != "" && i != "from" && i != "to") {
        filter[`${type == "create" ? i : `group_id.${i}`}`] = filters[i];
      }
      if ((i == "from" && i["from"] != "") || (i == "to" && i["to"] != "")) {
        filter[i] = filters[i];
      }
    }
    return filter;
  };

  const handleSearch = (search) => {
    setPage(1);
    if (value == 0) {
      setAddGroup([]);
      dispatch(
        getAllCreatedGroups(search, sort, sortBy, page, filterFun("create"))
      );
    } else if (value == 1) {
      dispatch(
        getAllGroupHistory(search, sort, sortBy, page, filterFun("history"))
      );
    }
    setLoaded(true);
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     debouncedHandleSearch(searchTerm);
  //   }
  //   return () => {
  //     debouncedHandleSearch.cancel();
  //   };
  // }, [searchTerm]);

  useEffect(() => {
    //to deal with search lagging
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    } else {
      setLoaded(true);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);
  
  const handleFilter = () => {
    setPage(1);
    if (value == 0) {
      setAddGroup([]);
      dispatch(
        getAllCreatedGroups(searchTerm, sort, sortBy, "", filterFun("create"))
      );
    } else if (value == 1) {
      dispatch(
        getAllGroupHistory(searchTerm, sort, sortBy, "", filterFun("history"))
      );
    }
  };
  const handleClear = () => {
    TabChange();
    if (value == 0) {
      setAddGroup([]);
      dispatch(getAllCreatedGroups(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(getAllGroupHistory(searchTerm, sort, sortBy, page, {}));
    }
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const exportCreatedGroupsCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
        split: false,
        status: "available",
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.createdGroups,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportGroupsHistoryCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.groupingHistory,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const exportGroupsHistoryNormalCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.groupingHistoryNormal,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    TabChange();
    setAddGroup([]);
    if (newValue == 0) {
      dispatch(getAllCreatedGroups("", sort, sortBy, page, {}));
    } else if (newValue == 1) {
      dispatch(getAllGroupHistory("", sort, sortBy, page, {}));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    importCreatedGroups(file);

    // Reset the file input value to allow multiple uploads
    e.target.value = null;
  };

  const importCreatedGroups = async (file) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
        "Content-Type": "multipart/form-data",
      },
    };
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("excelFile", file); // Append your Excel file to the FormData

      const response = await Axios.post(
        AllApis.bulk.createdGroups,
        formData,
        config
      );
      if (response?.data?.status === true) {
        dispatch(
          getAllCreatedGroups(
            searchTerm,
            sort,
            sortBy,
            page,
            filterFun("create")
          )
        );
        Swal.fire({
          title: "Uploaded",
          icon: "success",
          timer: 5000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        timer: 5000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(async () => {
    try {
      const groupNoList = await Axios.get(`${AllApis.dropdownList.group_no}`);
      setGroupNoList(groupNoList?.data?.result);

      const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
      setGradeList(gradeList?.data?.result);

      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (value == 0) {
      dispatch(getAllCreatedGroups(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(getAllGroupHistory(searchTerm, sort, sortBy, page, {}));
    }
  }, [sort, page]);

  useEffect(() => {
    //to deal with search input lagging
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    // Clean-up function to clear the timer in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Typography variant="h1" sx={{ mt: -4 }}>
        Grouping
      </Typography>
      <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Filters
        </Typography>
        <Box
          sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
        >
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={groupNoList || []}
              getOptionLabel={(option) => option?.toString() || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  group_no: newValue != null ? newValue : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Group No" />
              )}
            />
          </Div>

          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={gradeList || []}
              getOptionLabel={(option) => option?.grade_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  group_grade: newValue != null ? newValue?.grade_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.grade_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Grade" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={palleteList || []}
              getOptionLabel={(option) => option?.pallete_no || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  group_pallete_no:
                    newValue != null ? newValue?.pallete_no : "",
                  group_physical_location:
                    newValue != null ? newValue?.item_physical_location : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.pallete_no}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Pallet No" />
              )}
            />
          </Div>
          <FormControl sx={{ width: "23%" }} size="small">
            {(filters?.group_physical_location == "" ||
              filters?.group_physical_location == undefined) && (
              <InputLabel id="demo-select-small-label">
                Physical Location
              </InputLabel>
            )}
            {/* <Select
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%" }}
              size="small"
              labelId="demo-select-small-label"
              id="demo-simple-select"
              label="Physical Location"
              name="physical_location"
              defaultValue={""}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  group_physical_location: e.target.value ? e.target.value : "",
                });
              }}
            >
              <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
              <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
              <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
              <MenuItem value={"VENEER SECTION"}>VENEER SECTION</MenuItem>
              <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
            </Select> */}
            <TextField
              multiline
              size="small"
              label={
                (filters?.group_physical_location != "" ||
                  filters?.group_physical_location != undefined) &&
                "Physical Location"
              }
              value={filters?.group_physical_location || ""}
              sx={{ width: "100%", ...disabledStyling }}
              name="group_physical_location"
              disabled
              inputProps={{ shrink: true }}
              InputLabelProps={{
                style: { color: "#98A3AA" }, // Change the color of the label here
              }}
            />
          </FormControl>

          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="from"
                format="DD-MM-YYYY"
                name="from"
                label={"From Date of Grouping"}
                sx={datepickerStyling}
                defaultValue={filters.from !== "" ? dayjs(filters.from) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    from: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {filters?.to && !filters?.from && (
              <Typography
                sx={{
                  color: "#E73145",
                  fontSize: "0.75rem",
                  ml: 1,
                  mt: 0.5,
                }}
              >
                Select From Date of Grouping{" "}
              </Typography>
            )}
          </Div>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="to"
                format="DD-MM-YYYY"
                name="to"
                label="To Date of Grouping Date"
                sx={datepickerStyling}
                defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    to: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {!filters?.to && filters?.from && (
              <Typography
                sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
              >
                Select To Date of Grouping{" "}
              </Typography>
            )}
          </Div>
        </Box>
        <Box
          sx={{
            mt: 4,
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Div>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            {/* <Button variant="outlined" onClick={handleClears}> */}
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Div>
          <Div sx={{ display: "flex", columnGap: 2 }}>
            {/* {permissions.grouped_veneer_view && value == 0 && (
              <Button
                disabled={value == 0 && createdGroups?.length == 0}
                variant="contained"
                onClick={async () => {
                  value == 0 && exportCreatedGroupsCsv(filterFun("create"));
                }}
              >
                Export CSV
              </Button>
            )} */}
            {permissions.grouped_veneer_view && value === 1 && (
              <Button
                disabled={groupingReducer?.groupsHistory?.length === 0}
                variant="contained"
                onClick={async () => {
                  await exportGroupsHistoryNormalCsv(filterFun("group_id"));
                }}
              >
                Export Normal History CSV
              </Button>
            )}
            {permissions.grouped_veneer_view && (
              <Button
                disabled={
                  (value == 0 && createdGroups?.createdGroups?.length == 0) ||
                  (value == 1 && groupingReducer?.groupsHistory?.length == 0)
                }
                variant="contained"
                // onClick={async () => {
                //   value == 0 && exportIssuedGroupsCsv(filters);
                //   value == 1 && exportCreatedGroupsCsv(filterFun("create"));
                //   value == 2 && exportIssuedSmokingGroupsCsv(filterFun(""));
                //   value == 3 && exportIssuedDyingGroupsCsv(filterFun(""));
                //   value == 4 && exportGroupsHistoryCsv(filterFun("group_id"));
                // }}
                onClick={async () => {
                  value == 0 && exportCreatedGroupsCsv(filterFun("create"));
                  value == 1 && exportGroupsHistoryCsv(filterFun("group_id"));
                }}
              >
                Export CSV
              </Button>
            )}

            {/* {permissions?.grouped_veneer_create && value === 0 && (
              <Div>
                <form>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label htmlFor="fileInput">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Import
                    </Button>
                  </label>
                </form>
              </Div>
            )} */}
          </Div>
        </Box>
      </Div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Created Groups" {...a11yProps(0)} />
            <Tab label="Grouping History" {...a11yProps(1)} />
          </Tabs>
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setLoaded(false);
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("updated_at");
                if (value == 0) {
                  setAddGroup([]);
                  dispatch(
                    getAllCreatedGroups(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("create")
                    )
                  );
                } else if (value == 1) {
                  dispatch(
                    getAllGroupHistory(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("history")
                    )
                  );
                }
              }
            }}
            sx={{ width: 300, ml: "auto" }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Box>

        <CustomTabPanel value={value} index={0}>
          {loaded ? (
            <CreatedGroups
              filter={filterFun("create")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              addGroup={addGroup}
              setAddGroup={setAddGroup}
              palleteList={palleteList}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {loaded ? (
            <GroupHistoryList
              filter={filterFun("history")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default GroupedVeneerList;
